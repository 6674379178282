import React, { Component } from 'react'

import Layout from '../layouts/home'
import SEO from '../components/seo'

export default class SearchPage extends Component {
  componentDidMount() {
    const cx = '013780191407935163226:ak47ctw1qxq';
    const gcse = document.createElement('script');
    gcse.type = 'text/javascript';
    gcse.async = true;
    gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(gcse, s);
  }

  render() {
    return <Layout>
      <SEO
        title="Search on CeremonyNepal"
      />
      <section class="slider d-flex align-items-center">
        <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-md-12">
          <div>
            <div class="row">
              <div class="col-md-12">
                <div class="slider-content_wrap">
                  <h1>Search on CeremomyNepal</h1>
                  <p>Find useful information, links and services based on your search keywords.</p>
                  <div dangerouslySetInnerHTML={{ __html: '<gcse:search></gcse:search>' }} />
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        </div>
      </section>
    </Layout>
  }
}

